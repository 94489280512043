// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";


@import "./assets/styles/primeicons/primeicons.css";
@import "./assets/styles/primeng/resources/themes/nova/theme.css";
@import "./assets/styles/primeng/resources/primeng.min.css";

$brand-color-1: #0259A1;
$brand-accent: $brand-color-1;
$brand-color-dark: #2D323E;
$brand-warn: #b30838;

$backcolor-company-message: rgb(7, 130, 194);
$opacity-for-new-notification-backcolor: 0.3;

//#region  Setting default typography - BEGIN
html {
  font-size: 62.5%;
  font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif;
  line-height: 1.4 !important;
  letter-spacing: -0.1px !important;
}

body {
  font-size: 14px;
  font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif;
}

* {
  font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

html,
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

//#endregion Setting default typography

//#region Main Header-Top navbar
.header-wrapper.mat-toolbar {
  .header-container {
    &>.left {
      flex: unset !important;
    }

    &>.right {
      width: 100%;
      align-items: center !important;

      .left {
        flex: 1;
        display: inline-flex;
        height: -webkit-fill-available;
        max-height: none !important;
        min-height: 64px;

        fuse-shortcuts {
          display: flex;
          width: 100%;

          .nav-vertical-wrapper {
            display: flex;
            width: 100%;

            .bannerDiv {
              width: 100%;
              max-width: none;
              font-size: 13px;
              line-height: 22px;
              //font-weight: Bold;
              overflow-wrap: break-word;
              white-space: normal;

              .notifications-wrapper {
                justify-content: space-between !important;
                height: 100%;
                gap: 20px;

                .notifications-list {
                  width: auto;
                  display: flex;
                  height: 100%;
                  align-items: center;
                  width: -webkit-fill-available;

                  .bannerCompanyParagraph {
                    color: rgba(3, 155, 229, 1);
                  }

                  .bannerGlobalParagraph {
                    color: rgba(179, 8, 56, 1);
                  }

                  p {
                    line-height: 16px;
                  }
                }

                .notifications-icon-container {
                  display: flex;
                  height: 100%;
                  align-items: center;

                  .mat-icon {
                    margin: auto;
                    color: #fff;

                    .mat-badge-content {
                      top: 0;
                      line-height: 22px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

toolbar {
  position: sticky;
  top: 0;

  .mat-toolbar {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.05) !important;
    background-color: #fff !important;

    .header-container {
      background: #181A22;
      color: #fff;
    }
  }
}

//#endregion Main Header-Top navbar

//#region NOTIFICATIONS PANEL
.cdk-overlay-pane .mat-mdc-menu-panel.notification-menu {
  min-width: 350px !important;
  max-width: 350px !important;
  max-height: 100vh;
  border-radius: 0;
  min-height: 100vh;
  height: 100vh !important;
  position: fixed;
  right: 0;
  top: 0;
  transform-origin: right top !important;

  .notification-header {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
    margin: 0;
    padding: 15px 15px !important;

    &:after {
      content: "X";
      position: absolute;
      display: flex;
      right: 20px;
      top: 15px;
      cursor: pointer;
    }
  }

  .notification-items-wrapper {
    display: flex;
    padding: 15px;
    flex-direction: column;
    gap: 10px;

    .notification-item {
      z-index: 1;
      position: relative;
      display: flex;
      border-radius: 8px;
      background: rgba($backcolor-company-message, 0.08);
      border: 1px solid rgba($backcolor-company-message, 0.2);

      button {
        overflow: auto;
        white-space: normal;
        height: auto;
        line-height: normal;
        padding: 10px;
        border-radius: 8px;

        &:hover {
          cursor: default;
        }
      }

      hr {
        display: none;
      }

      &.success {
        border-color: rgba(63, 191, 2, 1);
        background: rgba(63, 191, 2, 0.1);
      }

      &.warning {
        border-color: rgba(255, 174, 0, 1);
        background: rgba(255, 174, 0, 0.1);
      }

      &.error {
        border-color: rgba(179, 8, 56, 1);
        background: rgba(179, 8, 56, 0.1);
      }

      .aderant-notification {
        $backcolor-company-message: rgb(244, 67, 54);
        background: rgba($backcolor-company-message, 0.1);
        border: 1px solid rgba($backcolor-company-message, 0.3);

        &:hover {
          background: rgba($backcolor-company-message, 0.15);
          cursor: default;
        }

        &.new-notification {
          font-weight: bolder;
          background-color: rgba($backcolor-company-message, $opacity-for-new-notification-backcolor);
          border: 1px solid rgba($backcolor-company-message, 1);
        }
      }
    }
  }

  .new-notification {
    font-weight: bolder;
    background-color: rgba($backcolor-company-message, $opacity-for-new-notification-backcolor);
    border: 1px solid rgba($backcolor-company-message, 1);
  }
}

//#endregion Notification Panel - END */

//#region BUTTONS
mat-stroked-button,
.mat-mdc-outlined-button {
  border-color: $brand-color-dark !important;

  &.mat-warn {
    border-color: $brand-warn !important;
  }
}

button {
  min-height: 42px;
}

.btn-down-margin {
  margin-top: 10px !important;
  border-top: 1px solid gray;

  .btn-down {
    width: 50px !important;
    height: 30px !important;
    line-height: 29px !important;
    border-radius: 0 0 6px 6px;
    border: 1px solid gray;
    border-top: none;
  }
}

mat-mini-fab {
  display: flex;
  align-items: center;
  justify-content: center;
}

//#endregion

//#region MAT FORM FIELDS
.form-row-container {
  margin-bottom: 1.34375em;
  margin-top: .25em;

  &:empty {
    display: none !important;
  }

  .mat-btn,
  .mat-btn-footer {
    margin: 20px 0;
  }

  .mat-btn-no-margin {
    margin: 0;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  >div,
  >button,
  >mat-form-field,
  >mat-checkbox,
  >mat-button-toggle-group {
    margin-right: 10px;

    &:only-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  >div {
    margin-right: 10px;

    &:only-child {
      margin-right: 0;
    }

    mat-form-field,
    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.no-margin-right {
  .form-row-container {

    >div,
    >mat-form-field {
      margin-right: 0;
    }
  }
}

.margin-right {
  .form-row-container {

    >div,
    >mat-form-field {
      margin-right: 10px;

      &:last-child {
        margin-right: 10px;
      }
    }
  }
}

mat-form-field {
  margin: 0;
  padding: 0;
  margin-right: 10px;
  font-size: 14px;

  textarea {
    margin: 0 0 10px 0px;
    max-height: 250px;
    overflow-y: scroll;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  padding-bottom: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-flex {
  align-items: center !important;
  display: flex !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-infix {
  padding: 0 !important;
  align-items: center !important;
  display: flex !important;
  min-height: 44px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label-wrapper {
  top: -1.344em;
  padding-top: 0.84375em;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -0.1em !important;
  padding: .1em 0;
}

// input.mat-input-element {
//   margin-top: -0.362em !important;
// }
textarea.mat-mdc-input-element {
  margin: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-subscript-wrapper {
  margin-top: 1.967em;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value-text {
  padding: .1em 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-underline {
  bottom: 0;
}

.mat-mdc-form-field[appearance="fill"] .mat-form-field-infix,
.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
  min-height: 36px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-trigger {
  height: 1.525em;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value {
  color: black;
  font-weight: 400 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  display: flex;
  align-items: center;

  mat-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .mat-datepicker-toggle {
    width: 40px;
    height: 40px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.294em) scale(.75);
  width: 133.33333%;
}

// mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
//   padding: 0;

//   input {
//     padding: .5em 0 !important;
//   }

//   mat-select {
//     padding: .35em 0;
//   }

//   textarea {
//     padding: .5em 0;
//     height: auto !important;
//   }
// }

// mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
//   top: -1.25em;
// }





// .mat-form-field-subscript-wrapper {
//   margin-top: 1.7916666667em;
//   margin-top: 2em;
// }
/* Text & Select box fixes */

//#endregion

//#region MAT EXPANSION PANELS
mat-expansion-panel,
.mat-expansion-panel {
  border: none;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px !important;
  margin-bottom: 5px !important;

  &.disabled-pointer {
    .mat-expansion-panel-header-title {
      color: rgba(0, 0, 0, .35);
    }
  }
}

//#endregion

.lookuptext {
  h4 {
    margin-top: 0;
  }
}

#main-display-header,
#autodocket-search {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.05);

  .search-container {

    .search-wrapper {
      width: 100%;
      max-width: none !important;
      gap: 20px;

      .search {
        padding: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 50px;
        align-items: center;
        gap: 20px;
        height: 48px;
        position: relative;

        .mat-SerachIcon {
          margin: 0;
          min-width: 40px;
          cursor: pointer;

          &.mat-accent {
            color: $brand-color-1;
          }
        }

        #Input-serach {
          border-radius: 50px;
          max-width: 100%;
          display: flex;
          text-indent: 0;
          font-size: 16px;
          padding: 0 20px !important;
          color: #333;

          &::placeholder {
            font-style: oblique;
            letter-spacing: 0.3px;
            opacity: 0.65;
          }
        }

        #Input-serach {
          border-radius: 50px 0 0 50px !important;

          +button {
            background: $brand-color-1;
            color: #fff !important;
            box-shadow: none !important;
            border: none;
            border-radius: 0 50px 50px 0 !important;
          }
        }
      }

      .btn-wrapper {
        align-items: center;
        border-radius: 50px;
        background: $brand-color-1;

        .select {
          background: transparent;
          margin: 0;
          padding: 0 20px;
          color: #fff;

          &:after {
            background: transparent;
            color: #fff;
          }
        }

        >div:last-child {
          position: relative;

          >.btn-group {
            position: absolute;
            right: 0;
            width: 112px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .mat-mdc-outlined-button {
        border-radius: 50px !important;
        color: $brand-color-dark !important;
      }
    }
  }

  >div {
    display: flex;
    width: 100%;
  }

  span:not:empty {
    padding-top: 20px;
  }
}

#autodocket-search {
  flex-direction: row;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  align-items: center;
  gap: 20px;
  height: 48px;

  .mat-icon {
    margin: 0;
    min-width: 40px;

    &.mat-accent {
      color: $brand-color-1;
    }
  }
}

#admin-management {
  >div {
    >div.content {
      background: transparent !important;
      padding: 20px 10px;
      max-width: calc(100% - 40px);
      display: flex;
      width: 100%;
      margin: auto;
      border-top: 5px solid #323846;
      border-radius: 18px 18px 0 0;
    }
  }
}

#autodocket {
  >div.content {
    background: transparent !important;
    padding: 20px 10px;
    max-width: calc(100% - 40px);
    display: flex;
    width: 100%;
    margin: auto;
  }
}

#quickdatecalculator {
  >div.content {
    background: transparent !important;
    padding: 20px 10px;
    max-width: calc(100% - 40px);
    display: flex;
    width: 100%;
    margin: auto;
  }
}

.search-container {
  background: white;
}

#autodocket {
  .content {
    width: 100%;
  }

  .rounded-container {
    width: 100%;
  }
}

.search-wrapper {
  display: flex;
  flex-direction: row;
}

.rounded-container {
  border-radius: 12px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background: white;
  overflow: hidden;
  padding: 24px;
}

hr {
  display: none;
}

.added-from-list {
  padding: 30px;
  border: 1px dotted #333;
  border-radius: 5px;
  text-align: center;
  margin-top: 0;
  width: 100%;
}

.mat-btn {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 0;

  button {
    min-height: 40px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    // &.mat-button-disabled {
    //   background-color: rgba(0, 0, 0, 0.12) !important;
    //   color: gray !important;
    //   pointer-events: none !important;
    // }
  }
}

.mat-btn-no-margin {
  margin: 0;

  button {
    min-height: 40px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.header.mat-btn {
  margin-top: 0;
}

.filter-Container {
  margin-bottom: 20px;

  .mat-mdc-form-field.filter-container {
    margin: 0;
  }
}

.mat-action-row,
mat-action-row {
  display: none !important;
}

.mat-btn-footer,
.mat-action-row {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 0;
  gap: 10px;

  >div {
    gap: 10px;
    display: flex;
  }

  button {
    margin: 0;
    border-radius: 50px;
    min-height: 40px;
    // margin-right: 10px;
    min-width: 120px;

    // &:last-child {
    //   margin-right: 0;
    // }
  }
}

.close-modal-button {
  margin-bottom: 15px !important;
}

.mat-mdc-table {
  >tbody>tr {
    >td {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-left: none !important;
      border-right: none !important;

      &:first-child {
        border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 0 8px 8px 0;
      }
    }

    &:hover {
      background: #DCEBF8;
      background: #E7F2FA;
    }
  }

  .mat-mdc-header-row {
    border-radius: 8px !important;
    background: transparent !important;
    border: none;

    .mat-mdc-header-cell,
    .mat-sort-header {
      color: #fff;
      font-size: 12px !important;
      padding: 10px 2px;
      line-height: 16px;
      border: none;

      p {
        margin: 0;
        padding: 0;
      }

      .mat-sort-header-content {
        letter-spacing: 0.5px;
        white-space: nowrap;
      }
    }

    >th,
    mat-header-cell {
      background: #2d323e !important;
      border: none;

      &:first-child {
        border-radius: 8px 0 0 8px !important;
        border: none;
      }

      &:last-child {
        border-radius: 0 8px 8px 0 !important;
        border: none;
      }
    }
  }

  thead {
    background: transparent !important;
    border: none;
  }
}

.mat-mdc-table {
  tr {
    &.mat-mdc-header-row {
      height: 46px;

      .mdc-checkbox {
        padding-top: 0;
        padding-bottom: 0;

        .mdc-checkbox__background {
          top: 0;
        }
      }

    }
  }

  .mat-mdc-header-row {

    >th {
      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
  }

  .mat-sort-header-arrow {
    color: #fff !important;
  }
}

.mat-mdc-paginator {
  background: transparent !important;
}

.mat-mdc-paginator-range-actions .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
    background: transparent;
}

.mat-horizontal-stepper-wrapper {
  width: 100%;
}

.mat-step-label {
  border-radius: 6px !important;
}

.mat-step-label.mat-step-label-selected {
  background: $brand-color-1 !important;
  color: #fff !important;
}

.mat-horizontal-content-container {
  padding: 0;

  .center {
    text-align: center;
    padding: 30px 0;
  }
}



mat-horizontal-stepper-wrapper {
  width: 100%;
}

// app-aln-main-display-header {
//   #main-display-header {
//     display: flex;
//     flex-direction: column;
//     padding: 20px;
//     background: #fff;
//     box-shadow: 0 30px 80px rgba(0, 0, 0, 0.05);

//     div:empty {
//       display: none;
//     }

//     .search-wrapper {
//       width: 100%;
//       max-width: none !important;
//       gap: 20px;

//       .search {
//         padding: 0;
//         border: 1px solid rgba(0, 0, 0, 0.15);
//         border-radius: 50px;
//         align-items: center;
//         gap: 20px;
//         height: 48px;

//         .mat-SerachIcon {
//           margin: 0;
//           min-width: 40px;

//           &.mat-accent {
//             color: $brand-color-1;
//           }
//         }

//         #Input-search {
//           border-radius: 50px;
//           max-width: 100%;
//           display: flex;
//           text-indent: 0;
//           font-size: 16px;
//           padding: 0 20px !important;

//           &::placeholder {
//             font-style: oblique;
//             letter-spacing: 0.3px;
//             opacity: 0.65;
//           }
//         }
//       }

//       .btn-wrapper {
//         align-items: center;
//         border-radius: 50px;
//         background: $brand-color-1;

//         .select {
//           background: transparent;
//           margin: 0;
//           padding: 0 20px;
//           color: #fff;

//           &:after {
//             background: transparent;
//             color: #fff;
//           }
//         }

//         >div:last-child {
//           position: relative;

//           >.btn-group {
//             position: absolute;
//             right: 0;
//             width: 112px;
//             display: flex;
//             flex-direction: column;
//           }
//         }
//       }
//     }

//     >div {
//       display: flex;
//       width: 100%;
//     }

//     .accent {
//       background-color: transparent !important;
//       padding: 0 !important;
//     }

//     span:not:empty {
//       padding-top: 20px;
//     }
//   }
// }

// app-alntrigger-selection-dailog,
// app-aln-content-panel,
// app-aln-case-info,
// app-aln-case-clone,
// app-report-parameters,
// app-aln-docket-detail-edit {

//   mat-expansion-panel,
//   .mat-expansion-panel {
//     border: none;
//     box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
//     border-radius: 12px;
//     margin-bottom: 5px;
//     width: 100%;

//     &.disabled-pointer {
//       .mat.mat-expansion-panel-header-title {
//         color: rgba(0, 0, 0, .35);
//       }
//     }
//   }
// }

// app-report-parameters {
//   padding: 10px;
//   background: rgba(0, 0, 0, 0.05);
//   border-radius: 12px;
//   border: 1px solid rgba(0, 0, 0, 0.15);
//   display: flex;
//   margin-top: 20px;
//   position: relative;

//   .mat-accordion {
//     gap: 2px;
//     display: flex;
//     flex-direction: column;

//     .mat-expansion-panel {
//       border-radius: 12px !important;
//       box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08) !important;
//     }

//     >.mat-expansion-panel-spacing {
//       margin: 0;
//     }

//     .mat-expansion-panel-header.mat-expanded {
//       height: 48px;
//       border: none;
//     }

//     .mat-expansion-panel-body {
//       padding-bottom: 0 !important;

//       .mat-card {
//         box-shadow: none;
//         margin: 0;
//         padding: 0;

//         .mat-card-header {
//           background: transparent !important;
//           max-width: 100%;
//           margin: 0;
//           padding: 15px 0 0;
//           // position: absolute;
//           border: none;

//           .mat-card-header-text {
//             margin: 0;

//             .mat-card-title {
//               font-size: 16px;
//               font-weight: 700;
//               color: $brand-color-1;
//               margin: 0;
//             }
//           }
//         }

//         .mat-card-content {
//           padding: 0;

//           .mat-dialog-actions {
//             padding: 0 0 15px;
//             min-height: auto;
//             margin: 0;
//             gap: 10px;
//             margin-bottom: 0;

//             // button {
//             //   margin: 0 !important;
//             //   padding: 0;
//             // }
//           }

//           .mat-paginator {
//             box-shadow: none;
//           }

//           .table-container {
//             box-shadow: none;
//             overflow: visible !important;

//             [mat-table] {
//               box-shadow: none;
//             }

//             .mat-table {
//               border-spacing: 0 1px;
//               border-collapse: separate;

//               .mat-header-row>th {
//                 &:first-child {
//                   border-radius: 8px 0 0 8px;
//                 }

//                 &:last-child {
//                   border-radius: 0 8px 8px 0;
//                 }
//               }

//               >tbody>tr {
//                 border-radius: 8px;
//                 border: 1px solid rgba(0, 0, 0, 0.15) !important;

//                 >td {
//                   border: 1px solid rgba(0, 0, 0, 0.15);
//                   border-left: none;
//                   border-right: none;

//                   &:first-child {
//                     border-radius: 8px 0 0 8px !important;
//                     border-left: 1px solid rgba(0, 0, 0, 0.15);
//                   }

//                   &:last-child {
//                     border-radius: 0 8px 8px 0 !important;
//                     border-right: 1px solid rgba(0, 0, 0, 0.15);
//                   }
//                 }
//               }
//             }
//           }

//           .pure-material-checkbox>span::before {
//             border-color: rgba(255, 255, 255, 0.65);
//           }
//         }
//       }

//       .mat-dialog-actions {
//         margin-bottom: 20px;
//       }

//       // .mat-dialog-actions button {
//       //   border-radius: 50px;

//       //   &[type="submit"] {
//       //     background: $brand-color-1 !important;
//       //     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
//       //     border: 1px solid $brand-color-1 !important;
//       //   }

//       //   &.btnClose {
//       //     border: 1px solid $brand-color-1 !important;
//       //     color: $brand-color-1 !important;
//       //     box-shadow: none !important;
//       //     border: 1px solid rgba(0, 0, 0, 0.65);
//       //     background-color: transparent !important;
//       //   }
//       // }
//     }

//     h3.mat-dialog-title {
//       font-size: 16px !important;
//     }
//   }

//   .close-button {
//     .mat-icon.mat-warn {
//       color: #DE5D00;
//     }
//   }
// }


app-case-management app-home #case-management>.center {
  box-shadow: none;
  align-items: center;

  >.content {
    background: transparent !important;
    padding: 20px 10px;
    border-top: 5px solid #323846;
    max-width: calc(100% - 40px);
    display: flex;
    width: 100%;
    border-radius: 18px 18px 0 0;

    app-case-home {
      width: 100%;

      #dashboard-project {
        overflow: visible;
        gap: 20px;

        >.content {
          max-width: none !important;
          background: #fff;
          box-shadow: 0 30px 80px rgba(0, 0, 0, 0.05);
          border-radius: 15px;
          padding: 20px;
          gap: 15px;
          display: flex;
          flex-direction: column;

          app-case-widgets {
            gap: 15px;
            max-height: fit-content !important;
            padding: 20px 0;

            fuse-widget {
              padding: 0 !important;
              display: flex;
              flex-direction: column;
              border-radius: 12px;

              >.fuse-widget-front {
                display: flex;
                height: 100%;
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
                border: none;
                background: transparent;
                box-shadow: none;
                // border: 1px solid $brand-accent !important;
                // background-color: rgba(19, 121, 206, 0.1);

                &.selected_widget {
                  border: unset !important;
                  background-color: rgba(19, 121, 206, 1);

                  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                  button div {
                    color: #fff !important;
                  }

                  button.widget {
                    border: unset !important;
                  }
                }
              }

              button.widget {
                min-height: 65px !important;
                min-height: 100% !important;
                padding: 8px 15px;
                // display: flex;
                align-items: center;
                border: 1px solid $brand-accent !important;
                background-color: rgba(19, 121, 206, 0.1);
                border-radius: 10px;
                /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                // .mat-button-wrapper {
                //   display: flex;

                >div {
                  padding: 0 !important;
                  flex-direction: row !important;
                  gap: 10px;
                  align-self: start;

                  >div {
                    &:first-child>div {
                      font-size: 28px;
                      line-height: 28px;
                      font-weight: bold;
                    }

                    >div {
                      color: #333 !important;
                    }
                  }

                  .secondary-text {
                    align-self: center;
                    text-align: left;
                    font-size: 14px;
                    flex: 100;
                    justify-self: self-start;
                    place-content: unset !important;
                    line-height: normal;
                  }
                }

                // }
              }

              .mat_refresh_desc {
                margin: 0;
                transform: none;
                position: absolute;
                min-width: fit-content;
                white-space: nowrap;
                right: -5px;
                top: -32px;
                letter-spacing: 0.3px;
                gap: 2px;

                mat-label {
                  display: flex;
                  align-items: center;
                  opacity: 0.5;
                }

                mat-icon {
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }

        >fuse-sidebar {
          position: relative;
          left: unset;
          right: unset;
          transform: none;
          background-color: #fff;
          padding: 20px;
          border-radius: 15px;
          visibility: unset !important;
          box-shadow: 0 30px 80px rgba(0, 0, 0, 0.05) !important;

          /* z-index: -1 !important; */
          >.content {
            border-radius: 15px;
            background: #fff;
            overflow: visible;

            fuse-widget {
              padding: 0;

              .fuse-widget-front {
                border: none;
                overflow: visible;
                gap: 8px;

                div {
                  padding: 0 !important;
                }

                >div:first-child {
                  gap: 2px;
                }
              }

              &:last-child {
                padding-top: 15px;
                border-top: 1px dashed rgba(0, 0, 0, 0.15);

                .fuse-widget-front>div {
                  &:first-child {
                    gap: 10px;
                    align-items: flex-start !important;

                    .td-header-info {
                      align-self: flex-start;
                    }
                  }

                  >.col {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                  }
                }
              }
            }

            .widget-group {
              gap: 20px;
              overflow: visible;
            }
          }
        }
      }
    }

    app-case {
      max-width: 100%;
      width: 100%;

      app-aln-content-panel {
        mat-expansion-panel {

          // border-radius: 12px;
          // box-shadow: 0 8px 20px rgba(0,0,0,0.08);
          /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
          .mat-card-wrapper {
            box-shadow: none !important;
            width: 100%;
            overflow: visible;

            .submenu-item {
              font-size: 14px !important;
              background: transparent;
              border: none;
              flex-direction: row !important;
              justify-content: flex-end !important;
              justify-content: flex-start !important;
              gap: 5px;
              white-space: nowrap;
              padding: 8px 12px;

              &.selected {
                background: $brand-color-1;
                color: #fff !important;
                margin: 0;
                border-radius: 12px 0 0 12px;
              }

              &:hover {
                box-shadow: none;
              }

              &.disableSelction:not(.selected) {
                opacity: 0.35;
              }
            }

            &:after {
              content: "";
              height: calc(100% + 14px);
              width: 15px;
              border-left: 2px solid $brand-color-1;
              position: absolute;
              left: 185px;
              border-radius: 14px;
              top: -14px;
            }
          }

          mat-action-row {
            padding: 0;

            >div:empty {
              display: none;
            }
          }

          .mat-expansion-panel-content .mat-expansion-panel-body {
            display: flex;
            flex-direction: column;

            .header {
              button {
                margin-bottom: 0;
                margin-left: 0;
                font-size: 13px;
                box-shadow: none;
                padding: 5px 10px;
                line-height: 32px;
                border-radius: 0;
                background: transparent;
                color: #333;
                background: rgba(5, 81, 158, 0.15);
                min-height: 36px;

                mat-icon {
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                  margin: 0;
                }

                /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                .mat-button-wrapper {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                }

                &:first-child {
                  border-radius: 8px 0 0 8px;
                  border-left: 1px solid rgba(5, 81, 158, 0.35);
                }

                &:last-child {
                  border-radius: 0 8px 8px 0;
                }

                &:only-of-type {
                  border-radius: 8px;
                }

                &:not([class*="disabled"]) {
                  background: $brand-accent;
                  color: #fff;
                  cursor: pointer;
                }

                &[disabled="true"] {
                  background-color: rgba(5, 81, 158, 0.15);
                  color: rgba(0, 0, 0, 0.5) !important;
                  cursor: default;
                }

                &[class*="disabled"] {
                  background-color: rgba(5, 81, 158, 0.15) !important;
                  cursor: default;
                  color: rgba(0, 0, 0, 0.5) !important;
                }
              }

              +div:not(.header) {
                padding-top: 10px;
                padding-top: 30px;
              }

              &:first-child {
                width: 100%;
                min-width: 100%;
                border-radius: 8px;
              }
            }

            .content.with-controls {
              display: flex;
              flex-direction: column;
            }

            .panel-main-content {
              app-case-details {
                app-aln-case-submenu-content {
                  margin: 0;
                  padding: 0 !important;
                  max-width: 185px !important;
                  box-shadow: none;
                  height: fit-content;
                  min-width: 185px;
                }

                app-aln-dynamic-component {
                  padding: 0 !important;

                  app-aln-case-info {
                    display: flex;
                    width: 100%;

                    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
                    .mat-card-wrapper #caseDetailsSection {
                      padding: 4px 0 0 !important;
                      overflow: auto !important;
                      height: auto !important;
                    }
                  }
                }

                app-aln-case-clone {
                  #copyDocketSection {
                    // padding: 0 !important;
                    // box-shadow: none;

                    mat-horizontal-stepper {
                      .mat-horizontal-content-container {
                        padding: 10px 0 10px !important;
                      }
                    }

                    .btnSavecopydct {
                      background: $brand-color-1;
                    }

                    // .btnCancelcopydct {
                    //   bottom: 0 !important;
                    //   position: absolute !important;
                    //   left: auto !important;
                    //   right: 2% !important;
                    // }
                  }
                }
              }

              app-docket {
                app-aln-submenu-content {
                  margin: 0;
                  padding: 0 !important;
                  max-width: 185px !important;
                  box-shadow: none;
                  height: fit-content;
                  min-width: 185px;
                }

                app-aln-dynamic-component {
                  // padding: 0 !important;
                  // max-width: calc(100% - 150px) !important;

                  app-aln-docket-detail-edit {
                    display: flex;
                    width: 100%;
                    min-height: calc(100% + 8px);

                    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
                    .mat-card-wrapper #docketSection {
                      padding: 4px 0 0 !important;
                      overflow: auto !important;
                      height: auto !important;
                    }
                  }
                }
              }

              app-event {
                app-aln-event-submenu-content {
                  margin: 0;
                  padding: 0 !important;
                  max-width: 185px !important;
                  box-shadow: none;
                  height: fit-content;
                  min-width: 185px;
                }

                app-aln-dynamic-component {


                  app-aln-event-detail {
                    display: flex;
                    width: 100%;

                    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
                    .mat-card-wrapper #eventSection {
                      padding: 4px 0 0 !important;
                      overflow: auto !important;
                      height: auto !important;

                      >form {
                        display: flex;
                        flex-direction: column;
                      }
                    }
                  }
                }
              }

              app-aln-docket-preview-list {
                padding: 0 !important;

                .docket-preview-list {
                  box-shadow: none;
                }
              }
            }

            .content-panel-with-nav {
              display: flex;
              gap: 25px;
              position: relative;

              >[class*="-submenu"] {
                padding-bottom: 30px !important;

                +[fxlayout="column"] .mat-mdc-card {
                  overflow: visible !important;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  app-aln-main-display-header,
  app-cm-sub-header,
  app-autodocket-index {
    width: 100%;
  }

  hr {
    display: none;
  }
}

app-case-management app-home #case-management>.center,
app-admin-management app-home #admin-management>.center {

  app-cm-sub-header,
  app-admin-sub-header {
    padding: 5px 0 0;

    .mat-mdc-icon-button svg {
      width: 40px;
      height: 40px;
    }
    .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
      display: none;
  }

    .accent {
      background-color: transparent !important;
      padding: 0 30px !important;
    }

    .sub-header {
      padding-left: 30px;
    }

    .available-project {
      color: #FFFFFF;
      padding: 8px 16px;
      height: 30px;
      line-height: 20px;
      font-size: 14px;
      border-radius: 8px 0px 0 0;
      cursor: pointer;
      background: transparent;
      color: #333;
    }

    .selected-project:not(button) {
      padding: 8px 16px;
      margin-right: 2px;
      line-height: 20px;
      font-size: 16px;
      border-radius: 8px 8px 0 0;
      border-top: 2px solid black;
      background: transparent;
      color: $brand-color-1;
      font-weight: bold;

      &:before {
        bottom: 0;
        left: 50%;
        border: solid rgba(0, 0, 0, 0);
        content: " ";
        position: absolute;
        pointer-events: none;
        border-top-color: transparent;
        border-width: 8px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom-color: #323846;
      }
    }

    [class*="-project"] {
      height: auto;
      position: relative;
      padding: 5px 10px 5px;
      align-items: center;

      .btntabCancel {
        position: absolute;
        top: 5px;
        right: 50px;
        width: 50px;

        &:before {
          content: "Close";
          width: fit-content !important;
          display: flex;
          padding: 5px 8px;
          font-family: "roboto";
          font-size: 11px;
          border-radius: 50px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          font-weight: 500;
          letter-spacing: 0.5px;
          color: #333;
        }
      }
    }

    >div:first-child {
      gap: 15px;
    }

    .available-project,
    .selected-project {
      margin-right: 6px;

      &:hover {
        background: rgba(255, 255, 255, 0.5) !important;
        transition: all ease 0.2s;
        border-radius: 5px !important;
      }
    }

    button.selected-project,
    button.available-project {
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;

      mat-icon {
        font-size: 28px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg{
          width: 45px;
          height: 45px;
        }
      }
    }

    .tabs-new {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      min-width: 80px;
    }

    .tabs-name {
      text-transform: uppercase;
      font-size: .8em;
      line-height: 1em;
    }

  }
}

// .mat-dialog-container-messages {
//   .mat-dialog-container {
//     max-width: 50%;
//   }
// }

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-container,
.mat-mdc-dialog-container {
  width: 100%;
  padding: 0 !important;
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.35) !important;
  border-radius: 15px !important;
  padding: 0;
  overflow: hidden !important;
  margin: 0 auto;
  resize: none !important;
  border: 1px solid #fff !important;
  background: #fff;
  fuse-confirm-dialog {
    max-width: 50%;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-header {
  justify-content: space-between;
  padding: 0 20px !important;
  background-color: $brand-color-dark !important;
  display: flex !important;
  align-items: center;
  color: white !important;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  flex: 0 1 auto;

  h1 {
    font-size: 20px;
  }

  >div:last-child .mat-icon {
    display: flex;
    width: 24px;
    height: 100%;
    min-width: fit-content;
    width: auto;
    color: #fff !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-mdc-dialog-content,
.mat-dialog-content {
  margin: 0 !important;
  max-width: 100%;
  min-width: 50vh;
  min-height: 150px;
  padding: 20px !important;
  height: auto;
  max-height: 75vh !important;
  overflow-y: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-footer {
  padding: 20px;
  display: flex;
  flex: 0 1 70px;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-actions,
.mat-mdc-dialog-actions {
  margin-bottom: 0 20px !important;
  min-height: auto !important;
}



.mat-action-row {
  border-top-style: none !important;
  border-top-width: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.mat-card-wrapper {
  box-shadow: none !important;
}

.print-preview-summary {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.dialog-actions-wrapper {
  >div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.repeater-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .repeater-primary {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .repeater-secondary {
    padding-bottom: 10px;

    &:empty {
      display: none;
    }
  }

  .repeater-tertiary {
    padding: 0 !important;
    position: relative;
    background: #fff;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
      gap: 5px;

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-label {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &.selectedExclusion {
      border-color: $brand-color-1;
      background: transparent !important;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 45px;
        height: 2px;
        right: -45px;
        top: calc(50% - 2px);
        background: $brand-color-1;
      }

      &:first-child {
        background: transparent !important;
        border: 2px solid $brand-color-1;
      }

      &:last-child {
        background: transparent !important;
        border: 2px solid $brand-color-1;
      }
    }
  }
}

.dialog-content-wrapper {
  &.aln-exclusions-list {
    .dialog-content-container {
      .mat-mdc-dialog-content {

        h1,
        h2 {
          margin: 0;
        }

        h1 {
          font-size: 18px;
          font-weight: 800;
          line-height: 29px;
          position: sticky;
          background: #fff;
          top: -20px;
          z-index: 2;
          width: calc(100% + 40px);
          margin-left: -20px;
          padding-left: 20px;
        }

        h2 {
          font-size: 12px;
          line-height: 28px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $brand-color-1;
          position: sticky;
          top: 8px;
          background: #fff;
          z-index: 1;
          width: calc(100% + 40px);
          margin-left: -20px;
          padding-left: 20px;

          +div {
            display: flex;
            gap: 2px;
            flex-direction: column;
          }
        }

        .mat-mdc-checkbox {
          display: flex;
          align-items: center;
          height: 100%;
        }

        .mat-mdc-checkbox-disabled .mdc-label {
          color: #333;
        }
      }

      >.mat-mdc-dialog-content.panel-left {
        box-shadow: none;
        padding-top: 0;
      }

      >.mat-mdc-dialog-content.panel-right {
        box-shadow: none;
        padding-left: 0;
        padding-top: 0;

        >div {
          border-left: 4px solid $brand-color-1;
          padding: 10px 0 15px 20px;
          border-radius: 15px;
          min-height: 100%;
          display: flex;
          flex-direction: column;
          gap: 15px;

          >div[fxlayout="row"] {
            padding: 8px 15px;
            background: rgba(0, 0, 0, 0.03);
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            align-items: center !important;
            gap: 10px;

            >div:last-child {
              button {
                margin-right: 0;
              }
            }
          }
        }

        .mat-accordion {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .mat-mdc-header-row {
            border-radius: 8px;
            padding: 10px 8px !important;
            box-shadow: none;

            .mat-mdc-header-cell {
              padding: 0;
            }
          }

          .mat-expansion-panel {
            border-radius: 8px !important;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05) !important;
            border: 1px solid rgba(0, 0, 0, 0.15);

            .mat-expansion-panel-header {
              gap: 12px;
              line-height: 18px;

              +.mat-expansion-panel-content>.mat-expansion-panel-body {
                padding: 0 12px 12px;

                >.expansion-panel {
                  background: transparent;
                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  >div {
                    display: flex;
                    flex-direction: column;
                    padding: 8px 12px;
                    background: #fff;
                    border-radius: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
                    gap: 3px;

                    >span {
                      margin: 0 !important;

                      &.field-label {
                        font-weight: 800;
                      }

                      &.field-value {
                        line-height: 18px;
                      }
                    }
                  }
                }
              }

              &:hover {
                background-color: transparent !important;
              }
            }
          }
        }

        .exclusions-print-preview {
          .toolbar-wrapper {
            align-items: center !important;
            padding: 0;
            border: none;
            background: transparent;
          }

          .filter-wrapper {
            flex-wrap: wrap;
            gap: 4px;
            justify-content: flex-start !important;

            >div {
              padding: 0 10px 0 0 !important;

              &:first-child {
                width: 100%;
                font-size: 14px;
              }
            }
          }

          #exclusionPrintPreview {
            border: 1px solid $brand-accent;
            border-radius: 12px;

            >div {
              &:not(:first-child) {
                padding: 10px 15px;
                border-top: 1px dashed rgba(0, 0, 0, 0.15);
                margin-top: 0 !important;
              }

              &:first-child {
                border-radius: 10px 10px 0 0;
                padding: 8px 0 10px !important;
              }

              &:nth-child(2) {
                border-top: none;
              }
            }

            input[type="checkbox"] {
              margin-right: 5px;
            }
          }
        }
      }
    }

    hr {
      display: none;
    }

    mat-label.hover-text {
      display: flex !important;
      position: absolute;
      top: calc(50% - 26px);
      font-style: normal;
      font-size: 12px;
      letter-spacing: 0;
      right: 7px;
      opacity: 0;
      transition: opacity 0.25s;
    }

    .hover-text-wrapper {
      flex-direction: column;

      &:hover .hover-text {
        opacity: 1;
        transition: opacity 0.25s;
      }

      button {
        line-height: 60px;
        width: 40px;
        padding: 0;
        height: 50px;
      }
    }

    .current-selection-wrapper>div:first-child {
      display: flex;
      flex-direction: column;
      gap: 2px;

      >span:first-child {
        font-weight: 800;
        font-size: 14px;
        color: #333;
      }
    }
  }

  >.mat-toolbar {
    &.mat-accent {
      background: $brand-color-dark !important;
    }

    .mat-toolbar-row {
      margin: 0 !important;
      line-height: 22px;
    }
  }

  .mat-mdc-dialog-actions {
    &.trigger-mat-dialog-actions {
      background: #fff !important;
      box-shadow: 0 -15px 30px rgba(0, 0, 0, 0.08) !important;
      position: relative;
      min-height: 60px;
      padding: 0 20px;
    }

    .mat-btn {
      display: flex;
      justify-content: flex-end;

      >div {
        display: flex;
        gap: 15px;

        // button {
        //   margin: 0 !important;
        //   border-radius: 50px;
        //   box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15) !important;
        //   min-width:120px;


        //   &.btnBack-custom {
        //     border: 1px solid $brand-color-1;
        //     color: $brand-color-1 !important;
        //     box-shadow: none !important;
        //     border: 1px solid rgba(0, 0, 0, 0.65);
        //     color: rgba(0, 0, 0, 0.65) !important;
        //   }

        //   &.btnCancel {
        //     border: 1px solid $brand-color-1;
        //     color: $brand-color-1 !important;
        //     box-shadow: none !important;
        //     border: 1px solid rgba(0, 0, 0, 0.65);
        //     color: rgba(0, 0, 0, 0.65) !important;
        //     background-color: transparent !important;
        //     min-width:120px;
        //   }

        //   &.btnSave,
        //   &.btnSave-custom {
        //     background: $brand-color-1 !important;
        //     min-width:120px;
        //   }
        // }
      }
    }
  }
}

// .print-preview-buttons-container {
//   button {
//     border-radius:6px;
//   }
// }
.print-preview-message-header {
  border-radius: 12px;
  padding: 10px 5px;
}

.schedule-Dates {
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 30px;
}

.reminder-Panel {
  border-right: 1px solid #ccc;
  margin-top: 30px;
  padding-left: 20px;

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  mat-radio-button {
    margin-right: 30px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
mat-radio-button {
  margin-bottom: 10px;
}

.results-Panel {
  margin-top: 30px;
  padding: 20px;

  >div {
    margin-bottom: 20px;
  }
}

.weekdays {
  margin-top: 20px;

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  mat-checkbox {
    margin-right: 30px;
  }
}

.reminder-Radio {
  margin-top: 30px;
}

.schedule-reminder {
  height: 50px;
  padding-left: 20px;
  margin-bottom: 30px;

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  mat-radio-button {
    margin-right: 30px;
  }
}

.reminder-Type {
  margin: 0% 2% 0% 2%;
}



.email-wizard-container {
  display: flex;
  gap: 20px;

  .left-block {
    border-radius: 12px;
    border: 1px solid #ccc;
    padding: 20px;
  }

  .right-block {
    border-radius: 12px;
    border: 1px solid #ccc;
    padding: 20px;
  }
}

.email-summary-label {
  font-size: 11px;
  text-transform: capitalize;
}

.jursdiction-status {
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-left: 20px;
  border-radius: 8px;
}

#headerMessage {
  font-size: 25px !important;
  line-height: 30px !important;
  color: #333;
}


//#region LOADING SPINNERS
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-all {
  pointer-events: all;
  z-index: 99999;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: wait;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
}

.spinner-align {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, .7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

//#endregion

//#region MODALS
.searchModel {
  //search modal opening from the search bar in Case Management and Autodocket
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 0;

  // &::before {
  //   background: rgba(0, 0, 0, .2);
  //   width: 100vw;
  //   height: 100vh;
  //   left: 0;
  //   top: 150px;
  //   content: '';
  //   position: fixed;
  //   mix-blend-mode: multiply;
  // }

  app-aln-advanced-search-dialog,
  app-aln-case-search-list,
  app-aln-client-search-list,
  app-aln-matter-list {
    border-radius: 12px;
    padding: 20px;
    background: #fff;
    top: 50px;
    position: absolute;
    max-height: 70vh;
    overflow-y: scroll;
    margin: 0 auto;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 2;
    left: 0;

    .tableStyling {
      max-height: 500px;
      overflow: auto;
    }
  }
}

@media (max-width:1280px) {
  .searchModel {

    app-aln-advanced-search-dialog,
    app-aln-case-search-list,
    app-aln-client-search-list,
    app-aln-matter-list {
      width: calc(100% - 60px);
      left: 30px;
      right: 30px;
      position: fixed;
      top: 140px;
    }
  }
}

/* The Modal (background) */
.modal-calculator {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh !important;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999;

  .modal-header {
    background-color: #2D323E;
    padding: 12px 24px;
    color: white;

    h1 {
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 20px;
  }

  /* Modal Content */
  .modal {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  div:has(.modal) {
    position: fixed;
    overflow: hidden;
  }

  .modal-content {
    position: absolute;
    background-color: #fefefe;
    margin: auto;
    width: 70%;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    overflow: hidden;
    height: auto;
    max-height: 80vh;
  }
}

app-aln-main-display-header:has(.modal) {
  overflow: visible;
}

//#endregion

// EMAIL

.ck-editor__editable {
  font-size: 13px;
}

.table.ck-widget {
  table {
    width: 100% !important;

    tr {
      td.ck-editor__editable:first-child {
        width: 120px !important;
      }
    }
  }
}

.ck-content .table {
  margin: 1em 0 !important;

  table td {
    padding: 10px !important;
  }
}

.ck-content figure {
  width: 100% !important;
}

// END OF EMAIL WIZARD






/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-footer {
  background: #fff !important;
  box-shadow: 0 -15px 30px rgba(0, 0, 0, 0.08) !important;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;

  .mat-btn-footer {
    align-items: center;
    margin: 0;
  }
}

.mat-mdc-dialog-title {
  padding: 15px !important;
  margin: 0 !important;
  background-color: #2D323E !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.dialog-content-container {
  position: relative;
  max-height: 100%;
  overflow: hidden !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline>div.mat-mdc-text-field-wrapper>div.mat-mdc-form-field-flex>div.mat-mdc-form-field-infix textarea {
  resize: vertical;
  overflow: visible;
  margin-top: 5px !important;
}

// div > .content:has(.caseModal)
// {
//   overflow-y: hidden;
//   max-height: 100%;
//   height: 100vh;
// }

fuse-sidebar.navbar-fuse-sidebar:has(+ #container-2 .caseModal) {
  z-index: 1 !important;
}
.mat-mdc-form-field {
  position: relative;
}
/* Form field alignment and error message fix - BEGIN */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-subscript-wrapper {
  top: auto;
  // margin-top: 2px;
  position: absolute !important;
  margin-top: 0;
  bottom: -15px;
  .mat-mdc-form-field-error-wrapper {
    padding: 0 12px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
div:not(.mat-form-field-appearance-legacy) .mat-form-field-infix {
  border-top: 5px solid transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(-1px) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-suffix mat-icon {
  transform: translateY(-1px);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label-wrapper {
  top: -15px;
}

/* Form field alignment and error message fix - END */


/* mat-table row spacing - BEGIN */
table.mat-mdc-table {
  border-spacing: 0 1px !important;
  border-collapse: separate !important;
}

/* mat-table row spacing - END */


/* CM toolbar adjustment - BEGIN */
app-case-management app-home #case-management>.center>.content app-case app-aln-content-panel mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .header button {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

app-case-management app-home #case-management>.center>.content app-case app-aln-content-panel mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .header button:only-of-type {
  border-right: none;
}

app-case-management app-home #case-management>.center>.content app-case app-aln-content-panel mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .header button:last-child {
  border-right: none;
}

/* CM toolbar adjustment - END */


/* begin - common css for child apps for mat-form-field */
.mdc-text-field--outlined .mdc-floating-label {
  left: 0px !important;
  font-size: 14px !important;
}

.mat-mdc-text-field-wrapper {
  min-height: 40px !important;
  padding: 0 12px !important;
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
  // padding: 8px 0 !important;
}

.mat-mdc-form-field-flex {
  height: 100%;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-color: rgb(161 161 161 / 38%) !important;
}

.mat-mdc-select-value-text {
  letter-spacing: normal;
  font-size: 14px;
  color: #000;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 1.4em !important;
  margin-left: 3px;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: 7px !important;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 0 !important;
}

.mdc-floating-label--float-above {
  transform: translateY(-1.90em) scale(0.75) !important;
}

/* for focused inputs */
.mat-focused {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
    border-color: #0259a1 !important;
    border-bottom-width: 2px !important;
    border-right-width: 2px !important;
    border-top-width: 2px !important;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-left-width: 2px !important;
  }
}


.mat-form-field-invalid {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
    border-color: #b30838 !important;
  }
}
button.mdc-button {
    line-height: normal;
    letter-spacing: normal;
}

/* for focused inputs */
.mat-focused {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
    border-color: #0259a1 !important;
    border-bottom-width: 2px !important;
    border-right-width: 2px !important;
    border-top-width: 2px !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-left-width: 2px !important;
  }
}

button.mdc-switch {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;

  .mdc-switch__icons {
    display: none;
  }

  &.mdc-switch--unselected .mdc-switch__track::before {
    background: rgba(0, 0, 0, 0.38) !important;
  }

  &.mdc-switch--unselected .mdc-switch__shadow {
    background: #fff !important;
  }

  &.mdc-switch--selected .mdc-switch__track::after {
    background: rgba(2, 89, 161, 0.54) !important;
  }
}

.mat-datepicker-content .mat-calendar {
  height: 100% !important;
}

.mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}

mat-option:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
  display: none;
}
.mat-calendar-body-label {
  line-height: 0 !important;
}
.mat-mdc-select-panel {
  padding: 0 !important;
}
/* end - common css for child apps for mat-form-field */